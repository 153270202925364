import { SILogger } from 'public/src/pages/common/monitor/index'
import HomeMonitor from 'public/src/pages/common/home_common/monitor.js'

const logLevelEnum = {
  1: 'logInfo',
  2: 'logWarn',
  3: 'logError'
}

/**
 * 组件过滤，sls上报,
 * logLevel: 告警方式
 * tag：标记名称
 * message: 过滤信息
 * reason 过滤原因
 * 
 * module:组件名（componentKey 可能为空）
 * tabName:tab名称
 * pageName：页面名称
 * cccId:组件cccid（可能为空)
 * position: 1(组件的位置,和埋点一致 从1开始)
 * 
 * immediate 是否立即触发
 *  */
const slsCompFilter = (
  params,
  immediate = false
) => {
  const { 
    logLevel = 1,
    tag = '',
    message = '',
    reason = '',
    module,
    tabName,
    pageName,
    cccId,
    position,
    traceId = '',
    extraInfo
  } = params
  const methodName = logLevelEnum[logLevel] || logLevelEnum[1]
  SILogger[methodName](
    {
      tag,
      message,
      params: {
        reason,
        module,
        tabName,
        pageName,
        cccId,
        position,
        traceId,
        extraInfo
      }
    },
    immediate
  )
  // warn monitor
  if (logLevel === 2) {
    HomeMonitor.metricWarnSlsCount({
      tags: {
        message,
      },
    })
  }
  // error monitor
  if (logLevel === 3) {
    HomeMonitor.metricErrorSlsCount({
      tags: {
        message,
      },
    })
  }
}

export default {
  slsCompFilter
}
